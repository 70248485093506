<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Supply to Farm</h2>
					</div>
				</div>
			</div>
		</div>
		<!-- tabs -->

		<!-- Table Container Card -->
		<b-card no-body class="mb-2">
			<b-tabs class="m-2" lazy>
				<b-tab active>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/svg/feed-supplement.svg')"
								style="margin-right: 5px; width: 20px"
							/>
							<span>Feed </span>
						</div>
					</template>
					<FeedSupplementList />
				</b-tab>
				<b-tab lazy>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/svg/medicine.svg')"
								style="margin-right: 7px; width: 17px"
							/>
							<span>Medicine</span>
						</div>
					</template>

					<medicine-list />
				</b-tab>
				<b-tab lazy>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/svg/vaccine.svg')"
								style="margin-right: 7px; width: 17px"
							/>
							<span>Vaccine</span>
						</div>
					</template>
					<vaccine-list />
				</b-tab>
				<b-tab>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/svg/chicks.svg')"
								style="width: 25px"
							/>
							<span>Chicks </span>
						</div>
					</template>
					<ChickList />
				</b-tab>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>

import vSelect from "vue-select";
import ChickList from "./ChickList.vue";
import VaccineList from "./VaccineList.vue";
import MedicineList from "./MedicineList.vue";
import FeedSupplementList from "./FeedSupplementList.vue";
// import OtherList from "./OtherList.vue";
export default {
	components: {
    vSelect,
    ChickList,
    VaccineList,
    MedicineList,
    FeedSupplementList,
    // OtherList
},
	data() {
		return {};
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}

.invoice-filter-select {
	min-width: 190px;

	::v-deep .vs__selected-options {
		flex-wrap: nowrap;
	}

	::v-deep .vs__selected {
		width: 100px;
	}
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
