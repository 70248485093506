<template>
	<div class="">
		<b-row class="mb-2">
			<!-- Date picker -->
			<b-col cols="12" md="7">
				<b-row>
					<b-col md="5  ">
						<label for="example-input">From Date</label>
						<b-form-datepicker
							id="from-date"
							placeholder="Choose a date"
							local="en"
							v-model="search_filter.from_date"
						/>
					</b-col>
					<b-col md="5 p-0">
						<label for="example-input">To Date</label>
						<b-form-datepicker
							id="to-date"
							placeholder="Choose a date"
							local="en"
							v-model="search_filter.to_date"
						/>
					</b-col>
					<b-col md="2 d-flex align-items-end">
						<b-button @click="getVaccineSupplyList()" variant="primary "> Filter </b-button>
					</b-col>
				</b-row>
			</b-col>
			<!-- Search -->
			<b-col cols="12" md="5" class="mt-2 d-flex justify-content-end align-items-center">
				<div>
					<b-form-input
						type="text"
						class="form-control"
						placeholder="Search"
						@input="getVaccineSupplyList()"
						v-model.trim="search_filter.input_field"
						style="border-radius: 4px"
					/>
				</div>
				<b-button
					@click="clearFilter"
					variant="secondary
 			 ml-1"
				>
					Clear
				</b-button>
				<b-button
					:to="{
						name: 'apps-supply-farm-vaccine',
					}"
					variant="primary mr-1 ml-2"
				>
					Add
				</b-button>
				<b-button @click="excelDownload()" variant="warning">
					<feather-icon icon="DownloadIcon" size="16" />
				</b-button>
			</b-col>
		</b-row>
		<div v-if="vaccineSupplyList && vaccineSupplyList.total > 0">
			<b-table
				id="vaccineSupplyList"
				:items="vaccineSupplyList.data"
				responsive
				:fields="fields"
				class="position-relative"
			>
				<template #cell(index)="data">
					<div class="ml-1">
						{{ vaccineSupplyList.from + data.index }}
					</div>
				</template>
				<template #cell(production_type)="data">
					{{ data.item.production_type.name }}
				</template>
				<template #cell(source)="data">
					{{ data.item.source.name }}
				</template>
				<template #cell(vaccine)="data">
					{{ data.item.vaccine.name }}
				</template>
			</b-table>
			<div class="mt-2 mb-5">
				<b-pagination
					:value="vaccineSupplyList.current_page"
					:total-rows="vaccineSupplyList.total"
					:per-page="vaccineSupplyList.per_page"
					align="right"
					@change="getVaccineSupplyList"
				>
				</b-pagination>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
			<h4 class="mt-2 Secondary">Supply Vaccine List Empty</h4>
		</div>
	</div>
</template>

<script>
import vSelect from "vue-select";
import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
import moment from "moment";
export default {
	components: {
		vSelect,
	},
	data() {
		return {
			searchQuery: null,
			page: null,
			fields: [
				"index",
				{
					key: "created_at",
					label: "purchase date",
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					},
				},
				{
					key: "farm.name",
					label: "farm name",
				},
				{
					key: "vaccine.name",
					label: "vaccine name",
				},
				{
					key: "batch_no",
					label: "batch_no",
				},
				"quantity",
				"total_cost",
			],
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: "",
			},
			vaccineSupplyList: {},
		};
	},
	created: function () {
		this.getVaccineSupplyList();
	},
	methods: {
		moment() {
			return moment();
		},

		getVaccineSupplyList(pageNo = 1) {
			const farm_id = this.$route.params.farmId;
			let url = `web/vaccine-supply-farm-list`;
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.vaccineSupplyList = response.data.vaccine_supply_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		excelDownload() {
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(this.search_filter.from_date).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format("YYYY-MM-DD");
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-vaccine-purchase`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getVaccineSupplyList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getVaccineSupplyList();
				});
		},

		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getVaccineSupplyList();
		},
	},
};
</script>

<style lang="scss" scoped>
.activeClass {
	background-color: #296db4;
	color: #fff;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
